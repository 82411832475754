import { getPosition } from "./meta-settings.js";
import Swiper from "./plugins/swiper-bundle.esm.browser.min.js";

AOS.init({
    startEvent: "load",
    disableMutationObserver: false,
    duration: 600,
    once: true,
});
AOS.refresh(true);

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`);

// We listen to the resize event
window.addEventListener("resize", () => {
    // We execute the same script as before
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    document.documentElement.style.setProperty(
        "--header-height",
        `${
            document.getElementsByClassName("header")[0].getBoundingClientRect()
                .height
        }px`
    );
});

const header = document.getElementsByClassName(`header`)[0];
// Header scroll
const scrollContainer = () => {
    return document.documentElement || document.body;
};

if (header && header.classList.contains("fixed")) {
    document.addEventListener("scroll", () => {
        if (scrollContainer().scrollTop > 0) {
            header.classList.add("scrolled");
        } else if (scrollContainer().scrollTop == 0) {
            header.classList.remove("scrolled");
        }
    });
}

// menu handlers

///check mobile menu show/hide condition
const mobileMenuStartPoint = +getComputedStyle(
    document.documentElement
).getPropertyValue("--mobile-menu-start-point");
let isMobileMenuEnable =
    $(window).outerWidth() <= mobileMenuStartPoint ||
    $(".header-mobile").length;

$(".dropdown-toggle").click(function (e) {
    if (isMobileMenuEnable) {
        //close all opened sub menu
        $(".menu-item.dropdown.active .dropdown-menu").slideUp({
            complete: function () {
                $(this).closest(".dropdown").removeClass("active");
            },
        });

        //open current submenu
        $(this).closest(".menu-item.dropdown").toggleClass("active");
        if ($(this).closest(".menu-item.dropdown").hasClass("active")) {
            e.preventDefault();
            $(this).next(".dropdown-menu").slideDown();
        }
    }
});

// toggle menu handler
function menuToggle() {
    $(".menu-toggle").toggleClass("active");
    $(".burger-text").toggleClass("active");
    $(".navbar-nav").toggleClass("active");
    $(".header-close-wrapper").toggleClass("active");
    // LockScroll when burger open and enable when closed and enable scroll on menu
    if (scrollLock.getScrollState()) {
        scrollLock.disablePageScroll(
            document.querySelector(".navbar-nav .menu")
        );
        scrollLock.addScrollableSelector(".simplebar-content-wrapper");
    } else {
        scrollLock.enablePageScroll();
    }
}
//menu update function
function updateMenu() {
    isMobileMenuEnable =
        $(window).outerWidth() <= mobileMenuStartPoint ||
        $(".mobile-header").length;
    if (!isMobileMenuEnable) {
        $(".dropdown-menu").css("display", "");
        $(".header-close-wrapper").removeClass("active");
        $(".menu-item.active").removeClass("active");
        $(".navbar-nav").removeClass("active");
        $(".burger-text").removeClass("active");
        $(".menu-toggle").removeClass("active");
        // LockScroll when burger open and enable when closed
        scrollLock.enablePageScroll();
    }
}
$(window).on("resize orientationchange", updateMenu);
// end of toggle menu handler

$(".menu-toggle").click(menuToggle); //menu toggles
$(".burger-text").click(menuToggle); //menu toggles
$(".header-close-wrapper").click(menuToggle); //menu toggles

// Приклад приєднання lottie на проекті (BOLD треба приєднювати тепер просто .gif)
// lottie.loadAnimation({
//   container: document.getElementById(``),
//   renderer: "svg",
//   loop: true,
//   autoplay: true,
//   path: "./js/lottie/name.json",
// });
// test

if (document.getElementsByClassName("swoosh-video-player")[0]) {
    const videoPlayerArr = document.querySelectorAll(".swoosh-video-player");

    for (let i = 0; i < videoPlayerArr.length; i++) {
        const player = new Plyr(videoPlayerArr[i], {
            controls: false,
            autoplay: true,
            ratio: "4:3",
            muted: true,
        });

        player.play();

        /*player.on("ended", (event) => {
            player.play();
        });*/

        player.on("ready", (event) => {
            const instance = event.detail.plyr;
            console.log(instance);
        });
    }
}

const videoPlayer = document.getElementsByClassName("video-player")[0];
if (videoPlayer) {
    const videoPlayersArr = document.querySelectorAll(".video-player");
    for (let i = 0; i < videoPlayersArr.length; i++) {
        const player = new Plyr(videoPlayersArr[i]);

        if (window.innerWidth >= 1024) {
            setTimeout(function () {
                const plyrArr = document.querySelectorAll(".plyr");
                for (let j = 0; j < plyrArr.length; j++) {
                    plyrArr[i].classList.add("plyr-hide-controls");
                }

                plyrArr[i].addEventListener("mouseenter", () => {
                    plyrArr[i].classList.remove("plyr-hide-controls");
                });
            }, 300);
        }
    }
}

const choicesDOM = document.querySelector(["[data-choices]"]);
if (choicesDOM) {
    const choicesArr = document.querySelectorAll([
        "[data-choices]:not(.select-with-checkboxes)",
    ]);
    for (let i = 0; i < choicesArr.length; i++) {
        const select = new Choices(choicesArr[i], {
            searchEnabled: false,
            itemSelectText: "",
            placeholder: true,
            /*removeItemButton: true,*/
            /*shouldSort: false,*/
        });

        select.passedElement.element.addEventListener(
            "addItem",
            function (event) {
                /*console.log(event.detail.id);*/
                if (
                    event.detail.id >= 1 &&
                    choicesArr[i].parentNode.querySelector(
                        ".choices__input--cloned"
                    )
                ) {
                    choicesArr[i].parentNode.querySelector(
                        ".choices__input--cloned"
                    ).style.display = "none";
                }
            },
            false
        );

        select.passedElement.element.addEventListener(
            "removeItem",
            function (event) {
                /*console.log(event.detail.id);*/
                if (
                    select.getValue().length < 1 &&
                    choicesArr[i].parentNode.querySelector(
                        ".choices__input--cloned"
                    )
                ) {
                    choicesArr[i].parentNode.querySelector(
                        ".choices__input--cloned"
                    ).style.display = "flex";
                }
            },
            false
        );
    }
    if (document.querySelector(["[data-choices].select-with-checkboxes"])) {
        const choicesCheckboxesArr = document.querySelectorAll([
            "[data-choices].select-with-checkboxes",
        ]);

        for (let i = 0; i < choicesCheckboxesArr.length; i++) {
            if (choicesCheckboxesArr[i].getAttribute("name") === "type[]") {
                const checkboxTypesArr = document.querySelectorAll(
                    'input[type="checkbox"][name="type[]"]'
                );

                const select = new Choices(choicesCheckboxesArr[i], {
                    searchEnabled: false,
                    itemSelectText: "",
                    placeholder: true,
                    removeItemButton: true,
                });
            }

            if (choicesCheckboxesArr[i].getAttribute("name") === "sector[]") {
                const checkboxSectorsArr = document.querySelectorAll(
                    'input[type="checkbox"][name="sector[]"]'
                );

                const select = new Choices(choicesCheckboxesArr[i], {
                    searchEnabled: false,
                    itemSelectText: "",
                    placeholder: true,
                    removeItemButton: true,
                });

                let selectedOptions = [];

                function handleCheckboxChange(event) {
                    select.setChoiceByValue(event.target.value);
                    console.log(select.setChoiceByValue(event.target.value));
                }
                function handleAddSelectChange(event) {
                    console.log(select.getValue());
                    select.getValue().forEach((value) => {
                        console.log(value.value);

                        checkboxSectorsArr.forEach((checkbox) => {
                            if (checkbox.value === value.value) {
                                checkbox.checked = true;
                            }
                        });
                    });
                }

                function handleRemoveSelectChange(event) {
                    select.getValue().forEach((value) => {
                        checkboxSectorsArr.forEach((checkbox) => {
                            console.log(checkbox.value === value.value);
                            console.log(checkbox.value);
                            if (checkbox.value === value.value) {
                                checkbox.checked = false;
                            }
                        });
                    });
                    const selectedOptions = Array.isArray(event.detail.value)
                        ? event.detail.value
                        : [event.detail.value];
                }
            }
        }
    }
}
if (document.getElementsByClassName("jobs-top-search-section")[0]) {
    const checkboxesTypes = document.querySelectorAll(
        'input[type="checkbox"][name="type[]"], input[type="checkbox"][name="type_second[]"]'
    );

    function syncCheckboxes(event) {
        const targetCheckbox = event.target;
        const isChecked = targetCheckbox.checked;
        const targetValue = targetCheckbox.value;

        checkboxesTypes.forEach((checkbox) => {
            if (checkbox.value === targetValue) {
                checkbox.checked = isChecked;
            }
        });
    }

    checkboxesTypes.forEach((checkbox) => {
        checkbox.addEventListener("change", syncCheckboxes);
    });

    const checkboxesSectors = document.querySelectorAll(
        'input[type="checkbox"][name="sector[]"], input[type="checkbox"][name="sector_second[]"]'
    );

    function syncCheckboxesSectors(event) {
        const targetCheckbox = event.target;
        const isChecked = targetCheckbox.checked;
        const targetValue = targetCheckbox.value;

        checkboxesSectors.forEach((checkbox) => {
            if (checkbox.value === targetValue) {
                checkbox.checked = isChecked;
            }
        });
    }

    checkboxesSectors.forEach((checkbox) => {
        checkbox.addEventListener("change", syncCheckboxesSectors);
    });
}

const defaultCustomSelect = document.getElementsByClassName(
    "default-custom-select"
)[0];
if (defaultCustomSelect) {
    const defaultCustomSelectArr = document.querySelectorAll(
        ".default-custom-select"
    );
    defaultCustomSelectArr.forEach((customSelect, i) => {
        const customSelectPlaceholder = customSelect.querySelector(
            ".default-custom-select__placeholder"
        );
        const customSelectList = customSelect.querySelector(
            ".default-custom-select__list"
        );
        const customSelectPlaceholderTextContent = customSelect.querySelector(
            ".default-custom-select__placeholder"
        ).textContent;

        customSelect.addEventListener("click", (e) => {
            if (!customSelect.classList.contains("active")) {
                customSelect.classList.add("active");
                console.log(customSelect);
            } else {
                if (
                    !e.target.classList.contains("default-custom-select__list")
                ) {
                    customSelect.classList.remove("active");
                }
            }
        });

        document.addEventListener("click", (event) => {
            if (
                !customSelectList.contains(event.target) &&
                !customSelect.contains(event.target)
            ) {
                customSelect.classList.remove("active");
            }
        });

        const checkboxesSelect = customSelect.querySelectorAll(
            'input[type="checkbox"]'
        );

        function placeholderCheck() {
            const checkedCount = customSelect.querySelectorAll(
                'input[type="checkbox"]:checked'
            ).length;
            console.log(
                customSelect.querySelectorAll('input[type="checkbox"]:checked')
            );

            if (checkedCount === 1) {
                const checkedItem = customSelect.querySelector(
                    'input[type="checkbox"]:checked'
                );
                customSelectPlaceholder.textContent =
                    checkedItem.nextElementSibling.nextElementSibling.textContent;
            } else {
                customSelectPlaceholder.textContent =
                    checkedCount > 0
                        ? `${checkedCount} items selected`
                        : customSelectPlaceholderTextContent;
            }
        }

        checkboxesSelect.forEach((checkbox) => {
            checkbox.addEventListener("change", () => {
                placeholderCheck();
            });
        });

        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        for (let j = 0; j < checkboxes.length; j++) {
            checkboxes[j].addEventListener("change", () => {
                placeholderCheck();
            });
        }
    });
}

const sectorsSlider = document.getElementsByClassName("sectors-slider")[0];

if (sectorsSlider) {
    const sectorsSwiper = new Swiper(".sectors-slider", {
        slidesPerView: 2,

        spaceBetween: 16,
        threshold: 10,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        navigation: {
            prevEl: ".sectors-slider-buttons.slider-buttons .slider-btn-prev",
            nextEl: ".sectors-slider-buttons.slider-buttons .slider-btn-next",
        },
        breakpoints: {
            552: {
                slidesPerView: 3,
                spaceBetween: 18,
            },

            852: {
                slidesPerView: 4,
                spaceBetween: 20,
            },

            1100: {
                slidesPerView: 5,
                spaceBetween: 20,
            },
        },
    });
}

const jobsSlider = document.getElementsByClassName("jobs-slider")[0];

if (jobsSlider) {
    const jobsSlidesArr = jobsSlider.querySelectorAll(".swiper-slide");
    let loop = false;
    let loopedSlides = false;

    if (jobsSlidesArr.length >= 5) {
        loop = true;
        loopedSlides = jobsSlidesArr.length * 3;
    }

    const jobsSwiper = new Swiper(".jobs-slider", {
        slidesPerView: 1.045,
        loop: loop,
        loopedSlides: loopedSlides,

        spaceBetween: 16,
        threshold: 10,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        navigation: {
            prevEl: ".jobs-slider-buttons.slider-buttons .slider-btn-prev",
            nextEl: ".jobs-slider-buttons.slider-buttons .slider-btn-next",
        },
        breakpoints: {
            452: {
                slidesPerView: 1.6,
                spaceBetween: 18,
            },

            652: {
                slidesPerView: 2,
                spaceBetween: 16,
            },

            768: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
        },
    });
}

const testimonialsSlider = document.getElementsByClassName(
    "testimonials-slider"
)[0];

if (testimonialsSlider) {
    const testimonialsSwiper = new Swiper(".testimonials-slider", {
        slidesPerView: 1,
        loop: true,
        loopedSlides: 14,

        spaceBetween: 20,
        threshold: 10,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        navigation: {
            prevEl: ".testimonials-slider-buttons.slider-buttons .slider-btn-prev",
            nextEl: ".testimonials-slider-buttons.slider-buttons .slider-btn-next",
        },
    });
}

const contactSlider = document.getElementsByClassName("contact-slider")[0];
if (contactSlider) {
    const nextBtn = document.querySelector(".contact-slider-next");
    const submitBtn = document.querySelector(".contact-slider-submit");
    const contactSliderLength =
        contactSlider.querySelectorAll(".swiper-slide").length - 1;

    const contactSwiper = new Swiper(".contact-slider", {
        spaceBetween: 20,
        slidesPerView: 1,
        navigation: {
            nextEl: ".contact-slider-next",
            prevEl: ".contact-slider-prev",
        },
        allowTouchMove: false,
    });

    contactSwiper.on("activeIndexChange", function (e) {
        if (contactSliderLength === e.activeIndex) {
            submitBtn.classList.add("active");
            nextBtn.classList.add("hidden");
        } else {
            submitBtn.classList.remove("active");
            nextBtn.classList.remove("hidden");
        }
    });
}

const cvSlider = document.getElementsByClassName("cv-slider")[0];
if (cvSlider) {
    const nextBtn = document.querySelector(".cv-slider-next");
    const cvSubmitBtn = document.querySelector(".cv-slider-submit");
    const cvSliderLength =
        cvSlider.querySelectorAll(".swiper-slide").length - 1;

    const cvSwiper = new Swiper(".cv-slider", {
        spaceBetween: 20,
        slidesPerView: 1,
        navigation: {
            nextEl: ".cv-slider-next",
            prevEl: ".cv-slider-prev",
        },
        allowTouchMove: false,
    });

    cvSwiper.on("activeIndexChange", function (e) {
        if (cvSliderLength === e.activeIndex) {
            cvSubmitBtn.classList.add("active");
            nextBtn.classList.add("hidden");
        } else {
            cvSubmitBtn.classList.remove("active");
            nextBtn.classList.remove("hidden");
        }
    });
}

const benefitsSlider = document.getElementsByClassName("benefits-slider");

if (benefitsSlider) {
    const benefitsSwiper = new Swiper(".benefits-slider", {
        slidesPerView: 1.045,
        spaceBetween: 16,
        threshold: 10,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        navigation: {
            prevEl: ".benefits-slider-buttons .slider-btn-prev",
            nextEl: ".benefits-slider-buttons .slider-btn-next",
        },
        breakpoints: {
            452: {
                slidesPerView: 1.6,
                spaceBetween: 18,
            },

            652: {
                slidesPerView: 2,
                spaceBetween: 16,
            },

            768: {
                slidesPerView: 3,
                spaceBetween: 20,
            },

            1190: {
                slidesPerView: 4,
                spaceBetween: 20,
            },
        },
    });
}

const approachSlider = document.getElementsByClassName("approach-slider");

if (approachSlider) {
    const approachSwiper = new Swiper(".approach-slider", {
        slidesPerView: 1.045,
        spaceBetween: 16,
        threshold: 10,
        slideToClickedSlide: true,
        /*autoplay: {
			delay: 3000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},*/
        /*navigation: {
			prevEl: ".approach-slider-buttons .slider-btn-prev",
			nextEl: ".approach-slider-buttons .slider-btn-next",
		},*/
        breakpoints: {
            768: {
                slidesPerView: 3,
                spaceBetween: 10,
                slideToClickedSlide: false,
            },

            951: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
        },
    });
}

const ourTeamSlider = document.getElementsByClassName("our-team-slider");

if (ourTeamSlider) {
    const ourTeamSwiper = new Swiper(".our-team-slider", {
        slidesPerView: 1.045,
        spaceBetween: 16,
        threshold: 10,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        navigation: {
            prevEl: ".our-team-slider-buttons .slider-btn-prev",
            nextEl: ".our-team-slider-buttons .slider-btn-next",
        },
        breakpoints: {
            720: {
                slidesPerView: 2,
                spaceBetween: 20,
            },

            768: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
        },
    });
}

const clientsSlider = document.getElementsByClassName("clients-slider")[0];
if (clientsSlider) {
    const clientsSwiper = new Swiper(".clients-slider", {
        slidesPerView: 2,
        spaceBetween: 16,
        grid: {
            rows: 2,
        },
        threshold: 10,
        /*loop: true,
		loopedSlides: 10,*/
        autoplay: {
            delay: 3500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        navigation: {
            prevEl: ".clients-slider-buttons .slider-btn-prev",
            nextEl: ".clients-slider-buttons .slider-btn-next",
        },
        breakpoints: {
            421: {
                slidesPerView: 2,
                spaceBetween: 18,
                grid: {
                    rows: 2,
                },
            },
            652: {
                slidesPerView: 3,
                spaceBetween: 16,
                grid: {
                    rows: 2,
                },
            },
            902: {
                slidesPerView: 4,
                spaceBetween: 20,
                grid: {
                    rows: 2,
                },
            },
            1100: {
                slidesPerView: 5,
                spaceBetween: 20,
                grid: {
                    rows: 2,
                },
            },
        },
    });
    // let clientsSwiper;
    //
    // function initializeSwiper() {
    // 	clientsSwiper = new Swiper(".clients-slider", {
    // 		slidesPerView: 1,
    // 		spaceBetween: 16,
    // 		threshold: 10,
    // 		loop: true,
    // 		loopedSlides: 12,
    // 		autoplay: {
    // 			delay: 3000,
    // 			disableOnInteraction: false,
    // 			pauseOnMouseEnter: true,
    // 		},
    // 		navigation: {
    // 			prevEl: ".clients-slider-buttons .slider-btn-prev",
    // 			nextEl: ".clients-slider-buttons .slider-btn-next",
    // 		},
    // 		breakpoints: {
    // 			421: {
    // 				slidesPerView: 2,
    // 				spaceBetween: 16,
    // 			},
    // 			768: {
    // 				slidesPerView: 3,
    // 				spaceBetween: 16,
    // 			},
    // 		},
    // 	});
    // }
    //
    // function destroySwiper() {
    // 	if (clientsSwiper) {
    // 		clientsSwiper.destroy();
    // 		clientsSwiper = undefined;
    // 	}
    // }
    //
    // function handleWindowResize() {
    // 	if (window.innerWidth <= 901) {
    // 		if (!clientsSwiper) {
    // 			initializeSwiper();
    // 		}
    // 	} else {
    // 		destroySwiper();
    // 	}
    // }
    //
    // handleWindowResize();
    // window.addEventListener("resize", handleWindowResize);
}

const lineClientsSlider = document.getElementsByClassName(
    "line-clients-slider"
);

if (lineClientsSlider) {
    const lineClientsSwiper = new Swiper(".line-clients-slider", {
        slidesPerView: 1,
        spaceBetween: 16,
        threshold: 10,
        loop: true,
        loopedSlides: 10,
        autoplay: {
            delay: 1800,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        breakpoints: {
            421: {
                slidesPerView: 2,
                spaceBetween: 16,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 16,
            },
            902: {
                slidesPerView: 4,
                spaceBetween: 20,
            },
            1100: {
                slidesPerView: 5,
                spaceBetween: 20,
            },
        },
    });
}

const gallerySlider = document.getElementsByClassName(
    "gallery-image-slider"
)[0];

if (gallerySlider) {
    const gallerySlidesArr = gallerySlider.querySelectorAll(".swiper-slide");
    let loop = false;
    let loopedSlides = false;

    if (gallerySlidesArr.length >= 4) {
        loop = true;
        loopedSlides = gallerySlidesArr.length * 3;
    }
    const gallerySwiper = new Swiper(".gallery-image-slider", {
        slidesPerView: "auto",
        spaceBetween: 20,
        loop: loop,
        loopedSlides: loopedSlides,
        threshold: 10,
        slideToClickedSlide: true,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        navigation: {
            prevEl: ".gallery-slider-buttons .slider-btn-prev",
            nextEl: ".gallery-slider-buttons .slider-btn-next",
        },
        breakpoints: {
            768: {
                spaceBetween: 16,
            },
        },
    });
}

const historySection = document.getElementsByClassName("history-section");

if (historySection) {
    const historyTimeSwiper = new Swiper(".history-time-slider", {
        slidesPerView: "auto",
        /*spaceBetween: 80,*/
        threshold: 10,
        slideToClickedSlide: true,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        navigation: {
            prevEl: ".history-slider-buttons .slider-btn-prev",
            nextEl: ".history-slider-buttons .slider-btn-next",
        },
        /*		breakpoints: {
			768: {
				spaceBetween: 186,
			},
		},*/
    });

    const historyContentSwiper = new Swiper(".history-content-slider", {
        slidesPerView: 1,
        spaceBetween: 40,
        threshold: 10,
        slideToClickedSlide: true,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        effect: "creative",
        creativeEffect: {
            prev: {
                translate: ["-120%", 0, -500],
                opacity: 0,
            },
            next: {
                translate: ["120%", 0, -500],
                opacity: 0,
            },
        },
    });

    historyContentSwiper.controller.control = historyTimeSwiper;
    historyTimeSwiper.controller.control = historyContentSwiper;
}

const ourValuesSlider = document.getElementsByClassName("our-values-slider")[0];
if (ourValuesSlider) {
    let ourValuesSwiper;

    function initializeValuesSwiper() {
        ourValuesSwiper = new Swiper(".our-values-slider", {
            slidesPerView: 1,
            spaceBetween: 16,
            threshold: 10,
            loop: !ourValuesSlider.classList.contains("work-for-type"),
            loopedSlides: ourValuesSlider.classList.contains("work-for-type")
                ? false
                : 12,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            navigation: {
                prevEl: ".our-values-slider-buttons .slider-btn-prev",
                nextEl: ".our-values-slider-buttons .slider-btn-next",
            },
            breakpoints: {
                421: {
                    slidesPerView: 2,
                    spaceBetween: 16,
                },
                652: {
                    slidesPerView: 3,
                    spaceBetween: 16,
                },
            },
        });
    }

    function destroyValuesSwiper() {
        if (ourValuesSwiper) {
            ourValuesSwiper.destroy();
            ourValuesSwiper = undefined;
        }
    }

    function handleWindowResize() {
        if (window.innerWidth <= 901) {
            if (!ourValuesSwiper) {
                initializeValuesSwiper();
            }
        } else {
            destroyValuesSwiper();
        }
    }

    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
}

const newsSlider = document.getElementsByClassName("news-slider");

if (newsSlider) {
    const newsSwiper = new Swiper(".news-slider", {
        slidesPerView: 1.045,

        spaceBetween: 16,
        threshold: 10,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        navigation: {
            prevEl: ".news-slider-buttons.slider-buttons .slider-btn-prev",
            nextEl: ".news-slider-buttons.slider-buttons .slider-btn-next",
        },
        breakpoints: {
            452: {
                slidesPerView: 1.6,
                spaceBetween: 18,
            },

            652: {
                slidesPerView: 2,
                spaceBetween: 16,
            },

            851: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
        },
    });
}

function parallax(e) {
    this.querySelectorAll(".swoosh-decor-parallax").forEach((elem) => {
        const speed = elem.getAttribute("data-speed");
        const x = (window.innerWidth - e.pageX * speed) / 400;
        const y = (window.innerHeight - e.pageY * speed) / 400;

        elem.style.transform = `translateX(${x}px) translateY(${y}px)`;
    });
}

if (
    window.innerWidth >= 1025 &&
    document.getElementsByClassName("swoosh-decor-parallax")[0]
) {
    document.addEventListener("mousemove", parallax);
}

const sectors = document.getElementsByClassName("all-sectors-container")[0];
if (sectors) {
    const tabBtn = sectors.querySelectorAll(".all-sectors-tab__btn");
    const tabItem = sectors.querySelectorAll(".all-sectors-tab__item");
    const contentItem = sectors.querySelectorAll(".all-sectors-content__item");
    /*solutionsAccordion.style.minHeight = `${solutionsAccordion.clientHeight}px`;*/

    tabItem.forEach((btn, i) => {
        btn.addEventListener("click", () => {
            const isActive = btn.classList.contains("active");

            const activeTabBtn = sectors.querySelector(
                ".all-sectors-tab__btn.active"
            );
            const activeTabItem = sectors.querySelector(
                ".all-sectors-tab__item.active"
            );
            const activeContentItem = sectors.querySelector(
                ".all-sectors-content__item.active"
            );

            if (!isActive) {
                if (activeTabBtn) {
                    activeTabBtn.classList.remove("active");
                    activeTabItem.classList.remove("active");
                    activeContentItem.classList.remove("active");
                }
                btn.classList.add("active");
                tabBtn[i].classList.add("active");
                contentItem[i].classList.add("active");
            }
        });
    });
}

const solutions = document.getElementsByClassName("solutions-container")[0];
if (solutions) {
    const tabBtn = solutions.querySelectorAll(".solutions-tab__btn");
    const tabItem = solutions.querySelectorAll(".solutions-tab__item");
    const contentBtn = solutions.querySelectorAll(".solutions-content__btn");
    const contentItem = solutions.querySelectorAll(".solutions-content__item");
    /*solutionsAccordion.style.minHeight = `${solutionsAccordion.clientHeight}px`;*/

    let prevIndex = 0;

    tabItem.forEach((btn, i) => {
        btn.addEventListener("click", () => {
            const isActive = btn.classList.contains("active");

            const activeTabBtn = solutions.querySelector(
                ".solutions-tab__btn.active"
            );
            const activeTabItem = solutions.querySelector(
                ".solutions-tab__item.active"
            );
            const activeContentBtn = solutions.querySelector(
                ".solutions-content__btn.active"
            );
            const activeContentItem = solutions.querySelector(
                ".solutions-content__item.active"
            );

            for (let j = 0; j < tabBtn.length; j++) {
                if (tabBtn[j].classList.contains("active")) {
                    prevIndex = j;
                }
            }

            if (activeTabBtn) {
                activeTabBtn.classList.remove("active");
                activeTabItem.classList.remove("active");
                activeContentBtn.classList.remove("active");
                activeContentItem.classList.remove("active");
            }

            if (isActive) return;

            btn.classList.add("active");
            contentBtn[i].classList.add("active");
            tabBtn[i].classList.add("active");
            contentItem[i].classList.add("active");
        });
    });

    contentBtn.forEach((btn, i) => {
        btn.addEventListener("click", () => {
            btn.classList.remove("active");
            tabBtn[i].classList.remove("active");
            tabItem[i].classList.remove("active");
            contentItem[i].classList.remove("active");

            contentBtn[prevIndex].classList.add("active");
            tabBtn[prevIndex].classList.add("active");
            tabItem[prevIndex].classList.add("active");
            contentItem[prevIndex].classList.add("active");
        });
    });
}

const latestNewsCategories = document.getElementsByClassName(
    "latest-news-result__categories"
)[0];

if (latestNewsCategories) {
    const categoriesSwiper = new Swiper(".latest-news-result__categories", {
        slidesPerView: "auto",
        freeMode: true,
        spaceBetween: 10,
        slideToClickedSlide: true,
    });
}

const latestNewsCardList = document.querySelector(
    ".latest-news-result__card-list"
);
if (latestNewsCardList) {
    const cardList = latestNewsCardList.querySelectorAll(".news-card");

    const isTabMatchMedia = matchMedia("(max-width: 901px)");
    console.log(isTabMatchMedia);
    function setDelimeters() {
        const oldDelimeters = latestNewsCardList.querySelectorAll(".delimeter");
        oldDelimeters.forEach((item) => item.remove());

        cardList.forEach((card, idx) => {
            const delimeter = document.createElement("span");
            delimeter.classList.add("delimeter");

            if (
                idx % 3 === 2 &&
                idx < cardList.length - 1 &&
                !isTabMatchMedia.matches
            ) {
                card.parentNode.insertBefore(delimeter, card.nextSibling);
            } else if (
                idx % 2 === 1 &&
                idx < cardList.length - 1 &&
                isTabMatchMedia.matches
            ) {
                card.parentNode.insertBefore(delimeter, card.nextSibling);
            }
        });
    }

    setDelimeters();

    isTabMatchMedia.addEventListener("change", setDelimeters);
}

const questions = document.getElementsByClassName("questions-tab")[0];
if (questions) {
    const tabBtn = questions.querySelectorAll(".questions-tab__btn");
    const tabItem = questions.querySelectorAll(".questions-tab__item");
    /*solutionsAccordion.style.minHeight = `${solutionsAccordion.clientHeight}px`;*/

    tabItem.forEach((btn, i) => {
        btn.addEventListener("click", () => {
            const isActive = btn.classList.contains("active");

            const activeTabBtn = questions.querySelector(
                ".questions-tab__btn.active"
            );
            const activeTabItem = questions.querySelector(
                ".questions-tab__item.active"
            );

            if (activeTabBtn) {
                activeTabBtn.classList.remove("active");
                activeTabItem.classList.remove("active");
            }

            if (isActive) {
                if (i !== 0) {
                    btn.classList.remove("active");
                    tabBtn[i].classList.remove("active");
                    tabBtn[0].classList.add("active");
                    tabItem[0].classList.add("active");
                }
            } else {
                btn.classList.add("active");
                tabBtn[i].classList.add("active");
            }
        });
    });
}

const newsContent = document.getElementById("news-content");

if (newsContent) {
    const headings = newsContent.querySelectorAll("h1, h2, h3, h4, h5, h6");
    const anchorsWrap = document.querySelector(
        ".open-news-main-content__contents-anchors"
    );
    console.log(headings);
    const anchorList = [...headings].forEach((el, idx) => {
        el.id = `anchor-${idx}`;

        const anchorLink = document.createElement("a");
        let anchorClass = "";

        switch (el.nodeName) {
            case "H2":
                anchorClass = "lvl1";
                break;
            case "H3":
                anchorClass = "lvl2";
                break;
            case "H4":
                anchorClass = "lvl3";
                break;

            default:
                break;
        }

        anchorLink.classList.add(anchorClass);
        anchorLink.href = `#anchor-${idx}`;
        anchorLink.textContent = el.textContent;

        anchorsWrap.appendChild(anchorLink);

        // return anchorLink;
    });
    console.log(anchorList);
}

const doubleRangeSliderInputs = () => {
    const doubleRange = document.getElementsByClassName(
        "double-range-inputs-yearly"
    )[0];
    if (doubleRange) {
        const slider = doubleRange.querySelector("#double-range-inputs");
        const max = +slider.dataset.max;
        const min = +slider.dataset.min;
        const step = +slider.dataset.step;
        const inputs = doubleRange.querySelectorAll(".double-range-input");
        const startValueMin = +inputs[0].value.replace(/[£,]/g, "");
        const startValueMax = +inputs[1].value.replace(/[£,]/g, "");

        noUiSlider.create(slider, {
            start: [startValueMin, startValueMax],
            connect: true,
            margin: 5,
            step: step,
            range: {
                min: min,
                max: max,
            },
        });

        slider.noUiSlider.on("update", function (values, handle) {
            console.log(inputs[handle].value);

            if (inputs[handle].value >= 160000) {
                inputs[handle].value =
                    Math.round(values[handle]).toLocaleString("en-US", {
                        style: "currency",
                        currency: "GBP",
                        minimumFractionDigits: 0,
                    }) + "+";
            } else {
                inputs[handle].value = Math.round(
                    values[handle]
                ).toLocaleString("en-US", {
                    style: "currency",
                    currency: "GBP",
                    minimumFractionDigits: 0,
                });
            }
        });

        inputs.forEach((elem, i) => {
            elem.addEventListener("input", function () {
                let value = elem.value.replace(/[£,]/g, "");
                slider.noUiSlider.set(value);
            });
        });

        slider.noUiSlider.on("end", function (values, handle) {
            if (inputs[handle].value === "£160,000") {
                inputs[handle].value = "£160,000+";
            }
            load(
                "jobs/search",
                "form:#search-form",
                "form:#search-form-2",
                "sort#sort"
            );
            return false;
        });
    }
};
doubleRangeSliderInputs();

const doubleRangeSliderInputsDaily = () => {
    const doubleRange = document.getElementsByClassName(
        "double-range-inputs-daily"
    )[0];
    if (doubleRange) {
        const slider = doubleRange.querySelector("#double-range-inputs-daily");
        const max = +slider.dataset.max;
        const min = +slider.dataset.min;
        const step = +slider.dataset.step;
        const inputs = doubleRange.querySelectorAll(".double-range-input");
        const startValueMin = +inputs[0].value.replace(/[£,]/g, "");
        const startValueMax = +inputs[1].value.replace(/[£,]/g, "");

        noUiSlider.create(slider, {
            start: [startValueMin, startValueMax],
            connect: true,
            margin: 5,
            step: step,
            range: {
                min: min,
                max: max,
            },
        });

        slider.noUiSlider.on("update", function (values, handle) {
            /*if (inputs[handle].value >= 160000) {
                inputs[handle].value =
                    Math.round(values[handle]).toLocaleString("en-US", {
                        style: "currency",
                        currency: "GBP",
                        minimumFractionDigits: 0,
                    }) + "+";
            } else {
                inputs[handle].value = Math.round(
                    values[handle]
                ).toLocaleString("en-US", {
                    style: "currency",
                    currency: "GBP",
                    minimumFractionDigits: 0,
                });
            }*/

            inputs[handle].value = Math.round(values[handle]).toLocaleString(
                "en-US",
                {
                    style: "currency",
                    currency: "GBP",
                    minimumFractionDigits: 0,
                }
            );
        });

        inputs.forEach((elem, i) => {
            elem.addEventListener("input", function () {
                let value = elem.value.replace(/[£,]/g, "");
                slider.noUiSlider.set(value);
            });
        });

        slider.noUiSlider.on("end", function (values, handle) {
            /*if (inputs[handle].value === "£160,000") {
                inputs[handle].value = "£160,000+";
            }*/
            load(
                "jobs/search",
                "form:#search-form",
                "form:#search-form-2",
                "sort#sort"
            );
            return false;
        });
    }
};
doubleRangeSliderInputsDaily();

const doubleRangeSliderInputsHourly = () => {
    const doubleRange = document.getElementsByClassName(
        "double-range-inputs-hourly"
    )[0];
    if (doubleRange) {
        const slider = doubleRange.querySelector("#double-range-inputs-hourly");
        const max = +slider.dataset.max;
        const min = +slider.dataset.min;
        const step = +slider.dataset.step;
        const inputs = doubleRange.querySelectorAll(".double-range-input");
        const startValueMin = +inputs[0].value.replace(/[£,]/g, "");
        const startValueMax = +inputs[1].value.replace(/[£,]/g, "");

        noUiSlider.create(slider, {
            start: [startValueMin, startValueMax],
            connect: true,
            margin: 5,
            step: step,
            range: {
                min: min,
                max: max,
            },
        });

        slider.noUiSlider.on("update", function (values, handle) {
            /*if (inputs[handle].value >= 160000) {
                inputs[handle].value =
                    Math.round(values[handle]).toLocaleString("en-US", {
                        style: "currency",
                        currency: "GBP",
                        minimumFractionDigits: 0,
                    }) + "+";
            } else {
                inputs[handle].value = Math.round(
                    values[handle]
                ).toLocaleString("en-US", {
                    style: "currency",
                    currency: "GBP",
                    minimumFractionDigits: 0,
                });
            }*/

            inputs[handle].value = Math.round(values[handle]).toLocaleString(
                "en-US",
                {
                    style: "currency",
                    currency: "GBP",
                    minimumFractionDigits: 0,
                }
            );
        });

        inputs.forEach((elem, i) => {
            elem.addEventListener("input", function () {
                let value = elem.value.replace(/[£,]/g, "");
                slider.noUiSlider.set(value);
            });
        });

        slider.noUiSlider.on("end", function (values, handle) {
            /*if (inputs[handle].value === "£160,000") {
                inputs[handle].value = "£160,000+";
            }*/
            load(
                "jobs/search",
                "form:#search-form",
                "form:#search-form-2",
                "sort#sort"
            );
            return false;
        });
    }
};
doubleRangeSliderInputsHourly();

const selectElement = document.getElementById("sort-salary");

if (selectElement) {
    const rangeArr = document.querySelectorAll(".double-range-inputs");
    rangeArr.forEach((range) => range.classList.add("hidden"));

    function handleSelectChange() {
        const selectedValue = selectElement.value;
        rangeArr.forEach((range) => {
            const className = `double-range-inputs-${selectedValue}`;
            if (range.classList.contains(className)) {
                range.classList.remove("hidden");
            } else {
                range.classList.add("hidden");
            }
        });
    }

    selectElement.addEventListener("change", handleSelectChange);
    handleSelectChange();
}

const singleRangeSlider = () => {
    const singleRange = document.getElementsByClassName(
        "single-range-default"
    )[0];
    if (singleRange) {
        const slider = singleRange.querySelector("#single-range-default");
        const max = +slider.dataset.max;
        const min = +slider.dataset.min;
        const unit = slider.dataset?.unit || "£";
        const step = +slider.dataset.step;
        const inputsHidden = singleRange.querySelector(
            ".single-range-hidden-input"
        );
        const inputInfo = singleRange.querySelector(".single-range-info");
        const startValue = +inputsHidden.value;

        noUiSlider.create(slider, {
            start: [startValue],
            connect: "lower",
            margin: 10,
            step: step,
            range: {
                min: min,
                max: max,
            },
        });

        slider.noUiSlider.on("update", function (values, handle) {
            inputInfo.textContent = `${Math.round(values[handle])} ${unit}`;

            inputsHidden.value = Math.round(values[handle]);
        });
    }
};
singleRangeSlider();

const btnShowHideArr = document.querySelectorAll(".btn-show-hide");
const showHideContentArr = document.querySelectorAll(".show-hide-content");

showHideContentArr.forEach((content, i) => {
    const items = content.querySelectorAll("li");
    const maxVisibleItems = content.classList.contains("with-sectors") ? 8 : 5;

    items.forEach((item, index) => {
        if (index > maxVisibleItems - 1) {
            item.classList.add("is-hidden");
        }
    });

    if (items.length > maxVisibleItems) {
        const btnShowHide = btnShowHideArr[i];
        btnShowHide.classList.add("is-visible");

        btnShowHide.addEventListener("click", () => {
            btnShowHide.classList.toggle("active");
            items.forEach((item, index) => {
                if (index > maxVisibleItems - 1) {
                    item.classList.toggle("is-hidden");
                }
            });
        });
    }
});

const teamCard = document.getElementsByClassName("team-card")[0];
if (teamCard) {
    const teamCardArr = document.querySelectorAll(".team-card");
    teamCardArr.forEach((card, i) => {
        const links = card.querySelectorAll(".socials__link");
        links.forEach((link, j) => {
            link.addEventListener("click", (e) => {
                e.stopPropagation();
            });
        });
    });
}

window.addEventListener("DOMContentLoaded", (event) => {
    const urlParams = new URLSearchParams(window.location.search);
    const sectorParam = urlParams.get("sector");

    if (sectorParam) {
        const section = $("#content-sticky");

        if (section.length > 0) {
            $("html, body").animate(
                {
                    scrollTop: section.offset().top,
                },
                "slow"
            );
        }
    }
});

window.addEventListener("DOMContentLoaded", (event) => {
    const urlParams = new URLSearchParams(window.location.search);
    const sectorParam = urlParams.get("consultant");

    if (sectorParam) {
        const section = $("#content-sticky");

        if (section.length > 0) {
            $("html, body").animate(
                {
                    scrollTop: section.offset().top,
                },
                "slow"
            );
        }
    }
});

const contentSticky = document.getElementById("content-sticky");
const rightSidebar = document.getElementById("sidebar");
if (rightSidebar) {
    const sidebarBuffer = rightSidebar.querySelector(".sidebar__buffer");
    const sidebarInner = rightSidebar.querySelector(".sidebar__inner");
    // const headerHeight = +getRootStyle("--header-height").replace("px", "");
    const headerHeight = 0;

    // gap on top and bottom of sticky sidebar
    const gap = 30;

    let lastScrollTop = 0;

    // if flag true === scrolling down else scrolling up
    let flag = null;

    // if flagHeight true === sidebar is higher than viewport height
    let flagHeight =
        document.querySelector(".sidebar__inner").getBoundingClientRect()
            .height >
        window.innerHeight - headerHeight;

    function calcHeightOfSidebar(flag) {
        const contentStickyTop = getPosition(contentSticky).y;
        const sidebarInnerTop = getPosition(sidebarInner).y;
        sidebarBuffer.style.height = `${sidebarInnerTop - contentStickyTop}px`;

        if (flag) {
            // scroll up
            sidebarInner.style.top = "";
            sidebarInner.style.bottom = `${
                -headerHeight -
                gap +
                window.innerHeight -
                sidebarInner.getBoundingClientRect().height
            }px`;
        } else {
            // scroll down
            sidebarInner.style.bottom = "";
            sidebarInner.style.top = `${
                Math.min(
                    window.innerHeight -
                        sidebarInner.getBoundingClientRect().height,
                    headerHeight
                ) - gap
            }px`;
        }
        flagHeight =
            sidebarInner.getBoundingClientRect().height >
            window.innerHeight - headerHeight;
    }

    function resetSticky() {
        sidebarInner.style.bottom = "";
        sidebarInner.style.top = `${headerHeight + gap}px`;
        sidebarBuffer.style.height = "0";
    }

    if (!flagHeight) {
        resetSticky();
    } else {
        calcHeightOfSidebar(false);
    }

    window.addEventListener("scroll", function () {
        let st = window.pageYOffset || document.documentElement.scrollTop;

        if (!flagHeight) {
            resetSticky();
        }

        if (st > lastScrollTop && !flag) {
            // scroll down
            calcHeightOfSidebar(flag);
            flag = true;
        } else if (st < lastScrollTop && flag) {
            // scroll up
            calcHeightOfSidebar(flag);
            flag = false;
        }
        lastScrollTop = st <= 0 ? 0 : st;
    });

    window.addEventListener("resize", function (e) {
        if (!flagHeight) {
            resetSticky();
        }

        calcHeightOfSidebar(true);
        calcHeightOfSidebar(false);
    });
}

if (document.getElementsByClassName("btn-filters")[0]) {
    const btnFilters = document.querySelectorAll(".btn-filters");
    const sidebar = document.querySelector(".sidebar");
    const jobsList = document.querySelector(".jobs-list");
    const jobsSearchContainer = document.querySelector(
        ".jobs-search-section__wrap"
    );
    /*const paginationSectionJobs = document.querySelector(
		".result-section.jobs-page"
	);*/

    btnFilters.forEach((btn, i) => {
        btn.addEventListener("click", () => {
            btnFilters.forEach((button) => {
                button.classList.toggle("active");
            });
            sidebar.classList.toggle("active");
            jobsList.classList.toggle("active");
            jobsSearchContainer.classList.toggle("active");
            /*paginationSectionJobs.classList.toggle("active");*/
        });
    });

    /*const container = document.querySelector(".btn-text-filter");

	const observer = new IntersectionObserver(
		(entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					// Контейнер достиг нижней границы
					// Выполните необходимые действия
					console.log(123);
				}

				console.log(222);
			});
		},
		{
			threshold: 0,
			rootMargin: "0% 0% -10% 0%",
		}
	);

	observer.observe(container);*/
}

const elements = document.querySelectorAll(".force-sticky");
Stickyfill.forceSticky();
Stickyfill.add(elements);

var scroll = new SmoothScroll('a[href*="#"]', {
    /*speed: 200*/
});

/*window.closePopup = function closePopup() {
    document.querySelector("#popup").classList.remove("active");
    scrollLock.clearQueueScrollLocks();
    scrollLock.enablePageScroll();
};*/
